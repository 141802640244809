@import "~antd/dist/antd.css";
@media only screen and (min-width: 539px) {
  .menu-container,
  .qr-code-app {
    top: 60px;
  }
}
@media only screen and (max-width: 539px) {
  .menu-container,
  .qr-code-app {
    top: 90px;
  }
}
input {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.ant-btn {
  border-radius: 10px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.ant-modal {
  width: 90vw !important;
}
.ant-picker-range {
  height: 30px;
}
.ant-select,
.ant-radio-group {
  width: 300px;
  margin-bottom: 1rem;
}
.ant-radio-group {
  display: flex;
  justify-content: space-evenly;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 10px;
}
.ant-table-cell > p {
  margin-bottom: 0;
}
.menu-container {
  max-width: 100vw;
  position: relative;
}
.app-about {
  position: fixed;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  margin: 2rem 1rem;
  font-size: 18px;
  height: 40vh;
}
.ant-menu-item {
  font-size: 16px;
  border-radius: 10px;
  margin: 0 5px 5px 0;
}
.qr-code-app {
  margin: 5rem 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.qr-code-app {
  text-align: center;
}

.qr-code-app button {
  margin-top: 10px; /* Adjust the margin as needed */
}
