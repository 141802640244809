@media only screen and (min-width: 767px) {
  .main > p:first-child {
    font-size: 63px;
  }
}
@media only screen and (max-width: 767px) {
  .main > p:first-child {
    font-size: 36px;
  }
}
.error {
  border: 1px solid #fc030d !important;
}
.main {
  position: relative;
  top: 115px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main > p:first-child {
  font-weight: 400;
  color: rgb(62, 63, 58);
  margin: 0;
}
.short-url {
  font-size: 20px;
  text-align: center;
}
.copy-button {
  margin-left: 0.5rem;
  cursor: pointer;
  background-color: #37783d;
  color: white;
  border-radius: 5px;
  padding: 1px 5px;
}
.copy-button:hover {
  background-color: #244f28;
}
.url-input {
  width: 70vw;
  height: 50px;
  margin: 1rem 0;
  border: 1px solid #c7c1c1;
  border-radius: 5px;
  padding: 0 15px;
  font-size: 20px;
}
.url-input:focus {
  outline: none;
}
.form-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.form-buttons > button {
  color: white !important;
  border: none;
  line-height: 22px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.form-buttons > button:hover {
  color: white;
  transform: scale(1.1);
}
.form-buttons > button:first-child {
  background-color: #29abe0;
  margin-right: 0.25rem;
}
.form-buttons > button:last-child {
  background-color: #ce4f0c;
  margin-left: 0.25rem;
}
.app-input:first-of-type {
  width: 220px;
}
.loader {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.loading {
  border: 2px solid #ccc;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-top-color: #1ecd97;
  border-left-color: #1ecd97;
  animation: spin 1s infinite ease-in;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
